<template>
    <div class="action-recommend-reject">
        <div class="title" v-html="title" />
    </div>
</template>
<script>
export default {
    name: 'ActionRecommendReject',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        title() {
            return this.$translate('ACTION_RECOMMEND_REJECT_TITLE').replace(/%s/, this.rejector.nickname)
        },
        user() {
            return this.chat.user
        },
        rejector() {
            const content = this.$mustParse(this.message.content)
            return content.user ? content.user : content
        },
        rejected() {
            return this.chat.user
        },
    },
}
</script>
